import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();
if (Foundation.MediaQuery.is('large')) {
    $(document).ready(function () {
        if($('.content').length > 0) {
            let topBarHeight = $( '.top-bar' ).innerHeight();
            $('.page-header .content').css('margin-top', topBarHeight);
            console.log('------------------------------------');
            console.log(topBarHeight);
            console.log('------------------------------------');
        }
    });
}